import i18n from "../../translations";
import { useContext, useEffect, useMemo, useState } from "react";
import { Button, ConfirmDialog, TextareaField } from "../../components";
import { CurrencyInputField } from "../../components/CurrencyInputField";
import { TagIcon, TrashIcon, ChevronLeftIcon, SearchIcon, SearchCircleIcon } from "@heroicons/react/outline";
import { SwitchToggleButton } from "../../components/SwitchToggleButton";
import { ImageInputField } from "../../components/ImageInputField";
import { FileInputField } from "../../components/FileInputField";
import { FilePdfOutlined } from "@ant-design/icons";
import { Loader } from "../../components/Loader";
import { useQuery } from "react-query";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { fetchOrderForClaimCreation, fetchUsages } from "../../services/orderServices";
import { StatusPill } from "../../components/BasicTable/StatusPill";
import { ORDER_STATUSES, TRACKING_STATUSES_DEFINITIONS } from "../../components/BasicTable/StatusPillDefinitions";
import { createClaim } from "../../services/claimServices";
import { DUPLICATE_ORDER, STORE_PATH } from "../../navigation/constants";
import { UserContext } from "../../hooks/UserContext";
import { CLAIM_ENABLED_STATUSES } from "./constants";
import { Checkbox } from "antd";
import { InputQuantity } from "../../components/InputQuantity";


const UMA_PRICE_APRIL_2024 = 108.57

const ProductSelectorTable = ({ allProductsSelected, handleAllProductsSelected, order, handleSelectedProducts, selectedProducts, allProducts, onAllProductsRadioChange, usagePrices, setUsagePrices, setSelectedProducts, allProductsValue, setAllProductsValue, isMexicanStore, hasNullPrice, discountPrice }) => {
    const [orderLines, setOrderLines] = useState([])
    useEffect(() => {
        setOrderLines(order?.order_lines)
    }, [])

    useEffect(() => {
        if (!hasNullPrice || (!(order?.total_price - order?.buyer_shipping_price) > 0)) setAllProductsValue(null)
        else if ((order?.total_price - order?.buyer_shipping_price) > 0) setAllProductsValue(usagePrices["product_value"])
        else setAllProductsValue(null)

        setUsagePrices(prevState => {
            const newState = {
                ...prevState,
                product_value: 0
            }

            return newState
        })
        if (hasNullPrice && (order?.total_price - order?.buyer_shipping_price) > 0) {
            const newProductsPricesAndQuantitiesList = order?.order_lines?.map(orderLine => {
                return {
                    ...orderLine,
                    price_per_item: orderLine?.price_per_item - orderLine?.discount_per_item,
                    reimbursable_quantity: orderLine?.quantity,
                    price: orderLine?.price_per_item === null ? 0 : isMexicanStore ? (orderLine?.price_per_item - orderLine?.discount_per_item) / 1.16 : (orderLine?.price_per_item - orderLine?.discount_per_item)
                }
            }).filter(result => result != null)
            setSelectedProducts(newProductsPricesAndQuantitiesList)
        } else {
            const newProductsPricesAndQuantitiesList = order?.order_lines?.map(orderLine => {
                return {
                    ...orderLine,
                    price_per_item: orderLine?.price_per_item - orderLine?.discount_per_item,
                    reimbursable_quantity: orderLine?.quantity,
                    price: null
                }
            }).filter(result => result != null)
            setSelectedProducts(newProductsPricesAndQuantitiesList)
        }
    }, [allProducts])

    const handleUpdateProductsPricesAndQuantities = (orderLine, price, quantity) => {
        const newProductsPricesAndQuantitiesList = selectedProducts?.map(product => {
            if (product.id === orderLine.id) {
                if (quantity == 0) {
                    return null
                } else {
                    return {
                        ...orderLine,
                        price_per_item: orderLine?.price_per_item - orderLine?.discount_per_item,
                        reimbursable_quantity: quantity != null ? quantity : product.quantity,
                        price: price != null ? price : product.price
                    }
                }
            }
            return product
        }).filter(result => result != null)
        setSelectedProducts(newProductsPricesAndQuantitiesList)
    }

    const handleFilter = (value) => {
        if (value.length > 0) {
            const newOrderLines = order?.order_lines?.filter(orderLine => {
                return (orderLine?.product?.name)?.toLowerCase().includes(value?.toLowerCase()) || (orderLine?.sku)?.toLowerCase().includes(value?.toLowerCase())
            })
            setOrderLines(newOrderLines)
        } else {
            setOrderLines(order?.order_lines)
        }
    }

    return (
        <div className="mt-2">
            <div className="mt-5 flex ">
                <label className="inline-flex items-center">
                    <input
                        type="radio"
                        className="form-radio"
                        value="ACCEPTED"
                        checked={allProducts === true}
                        onChange={(e) =>
                            onAllProductsRadioChange(e.target.value)
                        }
                    />
                    <span className="ml-2">{i18n.t('claims.create.product_selector_table.all_products')}</span>
                </label>

                <label className="inline-flex items-center ml-6">
                    <input
                        type="radio"
                        className="form-radio"
                        value="REJECTED"
                        checked={allProducts === false}
                        onChange={(e) =>
                            onAllProductsRadioChange(e.target.value)
                        }
                    />
                    <span className={"ml-2 " + (true ? "" : "text-gray-400")}>{i18n.t('claims.create.product_selector_table.some_products')}</span>
                </label>
            </div>
            <div className="flex gap-20 mt-5">
                <p>{i18n.t('claims.create.product_selector_table.products_to_reimburse')} {selectedProducts?.length}</p>
                {
                    hasNullPrice ?
                        <p>{`${i18n.t('claims.create.product_selector_table.amount_to_reimburse')} ${isMexicanStore ? `$${usagePrices["product_value"]} MXN` : `R$${usagePrices["product_value"]}`}`}</p>
                        :
                        <p>{`${i18n.t('claims.create.product_selector_table.amount_to_reimburse')} ${isMexicanStore ? `$${((usagePrices["product_value"]) / 1.16)?.toFixed(2)} MXN` : `R$${usagePrices["product_value"]}`}`}</p>

                }
            </div>
            {
                ((allProducts && (!(order?.total_price - order?.buyer_shipping_price > 0))) || (allProducts && hasNullPrice)) && (
                    <CurrencyInputField
                        icon={<TagIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                        required
                        help={isMexicanStore && i18n.t("claims.create.currency_preloaded.without_iva")}
                        className="sm:col-span-6 max-h-5 w-[34%] mt-3 pb-5 mb-3"
                        onChange={_value => setAllProductsValue(_value)}
                        value={allProductsValue}
                    />
                )

            }
            {!allProducts && (
                <>
                    <div className="mt-5">
                        <div className="flex">
                            <SearchIcon className="h-5 w-5 text-gray-400 mt-4" aria-hidden="true" />
                            <div className="border-b border-gray-400 mb-4 ml-2 w-[27%]">
                                <input
                                    type="text"
                                    className="block w-full border-none focus:outline-none bg-gray-50 text-sm"
                                    placeholder={i18n.t('claims.create.product_selector_table.table.filter_placeholder')}
                                    onChange={(e) => handleFilter(e.target.value)}
                                />
                            </div>

                        </div>
                    </div>
                    <table className="w-full mt-3 table-fixed">
                        <thead className="border-b">
                            <tr className="text-left">
                                <th className="w-2/6">
                                    <div className="flex items-center">
                                        <Checkbox
                                            checked={allProductsSelected}
                                            onChange={({ target: { checked } }) => handleAllProductsSelected(checked)}
                                        />
                                        <div className="ml-5 flex">
                                            <p className="mb-1">{i18n.t('claims.create.product_selector_table.table.headers.name')}</p>&nbsp;|&nbsp;
                                            <p>{i18n.t('claims.create.product_selector_table.table.headers.sku')}</p>
                                        </div>
                                    </div>
                                </th>
                                <th className="w-2/6">{i18n.t('claims.create.product_selector_table.table.headers.price')}</th>
                                <th className="w-1/6">{i18n.t('claims.create.product_selector_table.table.headers.sent')}</th>
                                <th className="w-1/6">{i18n.t('claims.create.product_selector_table.table.headers.reimbursed')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orderLines?.map((order_line, index) => {
                                let isChecked = false
                                let inputValue = 0
                                let price = null
                                selectedProducts?.forEach(selectedProduct => {
                                    if (selectedProduct.id === order_line.id) {
                                        if (!isChecked) isChecked = true
                                        inputValue = selectedProduct?.reimbursable_quantity
                                        price = selectedProduct?.price
                                    }
                                })
                                return (
                                    <tr key={index}>
                                        <td className="w-2/6">
                                            <div className="flex items-center">
                                                <Checkbox
                                                    key={index}
                                                    checked={isChecked}
                                                    onChange={({ target: { checked } }) => handleSelectedProducts(order_line, checked)}
                                                />
                                                <div className="ml-5">
                                                    <span className="block">
                                                        {order_line?.product?.name}
                                                    </span>
                                                    {
                                                        order_line?.sku && (
                                                            <span className="w-fit px-2.5 py-0.5 rounded-full text-xs font-medium mx-1 bg-blue-100 text-blue-800 block">
                                                                {order_line?.sku}
                                                            </span>
                                                        )
                                                    }

                                                </div>
                                            </div>
                                        </td>
                                        <td className="w-2/6 items-start">
                                            {(!hasNullPrice && (order?.total_price - order?.buyer_shipping_price) > 0) ?
                                                <p>
                                                    {isMexicanStore ?
                                                        `$${((order_line?.price_per_item - order_line?.discount_per_item) / 1.16)?.toFixed(2)} MXN`
                                                        :
                                                        `R$${(order_line?.price_per_item - order_line?.discount_per_item)?.toFixed(2)}`
                                                    }
                                                </p>
                                                :

                                                <CurrencyInputField
                                                    icon={<TagIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                                                    required
                                                    className="sm:col-span-6 max-h-5 w-[70%] mb-10 mt-4 text-sm"
                                                    onChange={(value) => handleUpdateProductsPricesAndQuantities(order_line, value, null)}
                                                    help={isMexicanStore && i18n.t("claims.create.currency_preloaded.without_iva")}
                                                    value={(hasNullPrice && ((order?.total_price - order?.buyer_shipping_price) > 0)) ? (price ? price : "") : (price ? price : "")}
                                                    isDisabled={!isChecked}
                                                />
                                            }
                                        </td>
                                        <td className="w-1/6">
                                            <p>
                                                {order_line?.quantity}
                                            </p>
                                        </td>
                                        <td className="w-1/6">
                                            <div><InputQuantity initialValue={inputValue} updateData={(value) => { handleUpdateProductsPricesAndQuantities(order_line, null, value) }} maxValue={order_line?.quantity} /></div></td>

                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </>

            )}
        </div>
    )
}

const MainButtons = (
    {
        onSubmit,
        onCancel,
        disableSubmit
    }) => (
    <div className="grid grid-cols-2 place-content-center mt-10">
        <div className="px-10">
            <Button className="w-full" type="secondary" onClick={() => onCancel()}><span className="text-lg">{i18n.t("claims.create.close_button")}</span></Button>
        </div>
        <div className="px-10">
            <Button disabled={disableSubmit} className="w-full" type="primary" onClick={() => onSubmit()}><span className="text-lg">{i18n.t("claims.create.save_button")}</span></Button>
        </div>
    </div>
)

const RadioItem = function (
    {
        motive,
        show,
        onChange,
        checked,
        text,
        onEditSelection,
        disabled = false,
        errorMessage = ""
    }) {
    const [showErrorMessage, setShowErrorMessae] = useState(false)

    const handleInputOnChange = (event) => {
        if (disabled) {
            setShowErrorMessae(true)
        } else {
            onChange(event.target.checked)
        }
    }

    if (!show) return null

    return (
        <div className="relative flex items-start">
            <div className="flex h-6 items-center">
                {!checked &&
                    <input
                        id={motive + "RadioItem-id"}
                        name={motive + "RadioItem"}
                        type="radio"
                        className={`h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500`}
                        checked={checked}
                        onChange={event => handleInputOnChange(event)}
                    />
                }
                {checked &&
                    <button
                        className="text-gray-500 hover:text-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={() => onEditSelection()}
                    >
                        <span className="sr-only">{i18n.t('claims.create.problems_list.edit_selection')}</span>
                        <ChevronLeftIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                }
            </div>
            <div className="ml-4 text-sm flex flex-col">
                <label htmlFor={motive + "RadioItem-id"} className={`text-base text-gray-700 ${disabled ? "opacity-50" : ""}`}>
                    {text}
                </label>
                {
                    disabled && showErrorMessage && (
                        <label className="text-red-500 text-sm">
                            {errorMessage}
                        </label>
                    )
                }
            </div>
        </div>
    )
}

const OrderDetail = (
    {
        value,
        label,
        id,
        child
    }) => (
    <div className="sm:col-span-3">
        <label htmlFor={id} className="block text-sm font-medium text-gray-700">
            {label}
        </label>
        {!child &&
            <div className="mt-1">
                <input
                    type="text"
                    id={id}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    disabled
                    value={value}
                />
            </div>
        }
        {child &&
            <div className="mt-2">
                {child}
            </div>
        }
    </div>
)

const TextSection = function (
    {
        title,
        text,
        textList
    }) {
    return (
        <div>
            <div className="text-base font-bold text-gray-900">{title}</div>
            {text && <div className="text-base text-gray-700">{text}</div>}
            {textList &&
                <div className="text-base text-gray-700 mt-2 pl-4">
                    <ul className="list-disc space-y-1">
                        {textList.map(listElement => <li key={listElement}>{listElement}</li>)}
                    </ul>
                </div>
            }
        </div>
    )
}

const ImageUploadSection = function (
    {
        required,
        label,
        images = [],
        onChange
    }) {
    const [imageError, setImageError] = useState(null)

    const filesChangeHandler = (files) => {
        if (files.length > 0) {
            setImageError(null)
            onChange([...images, ...files])
        }
    }

    const removeImage = (id) => {
        onChange(images.filter((img) => img.id !== id))
    }

    return (
        <div>
            <ImageInputField
                required={required}
                label={label}
                onChange={(files) => filesChangeHandler(files)}
                onError={(error, file, message) => setImageError(message)}
                maxFileSize={30000000}
            />
            {imageError && (
                <div className="text-red-500 text-center italic mt-2">
                    {imageError}
                </div>
            )}
            <div className="flex flex-wrap justify-center">
                {images.map((image) => (
                    <div
                        className="w-32 h-32 m-2 bg-cover bg-no-repeat bg-center rounded shadow relative"
                        style={{
                            backgroundImage: `url('${image.preview?.url}')`,
                        }}
                        key={String(image.id)}
                    >
                        <div
                            onClick={() => removeImage(image.id)}
                            className="absolute -right-1 -top-1 rounded-full bg-white border border-gray-600 p-1 cursor-pointer"
                        >
                            <TrashIcon className="h-4 w-4 text-gray-600" />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

const FilesUploadSection = function (
    {
        required,
        label,
        files = [],
        onChange
    }) {
    const [fileError, setFileError] = useState(null)

    const filesChangeHandler = (_files) => {
        if (_files.length > 0) {
            setFileError(null)
            onChange([...files, ..._files])
        }
    }

    const removeFile = (id) => {
        onChange(files.filter((file) => file.id !== id))
    }

    return (
        <div>
            <FileInputField
                required={required}
                label={label}
                onChange={(files) => filesChangeHandler(files)}
                onError={(error, file, message) => setFileError(message)}
                maxFiles={4}
                accepts={['application/pdf', 'text/xml']}
                placeholder={i18n.t('claims.create.files_field_placeholder')}
            />
            {fileError && (
                <div className="text-red-500 text-center italic mt-2">
                    {fileError}
                </div>
            )}
            <div className="flex flex-wrap justify-center">
                {files.map((file) => (
                    <div
                        className="mt-3 grid grid-cols-4 border-b border-gray-200"
                        key={String(file.id || file.document_id)}
                    >
                        <p className="col-span-3">
                            <FilePdfOutlined className="text-center text-2xl w-8" />{file.name || file.filename}
                        </p>
                        <div>
                            <div className="ml-2 absolute rounded-full bg-white border border-gray-600 p-1 cursor-pointer">
                                <TrashIcon onClick={() => removeFile(file.id || file.document_id)} className="h-4 w-4 text-gray-600" />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

const ToggleSection = function (
    {
        title,
        onChange,
        text,
        value = false
    }) {
    return (
        <div>
            <div className="text-base font-bold text-gray-900">{title}</div>
            <div className="mt-2 flex items-center">
                <div className="">
                    <SwitchToggleButton
                        value={value}
                        onChange={input => onChange(input)}
                    />
                </div>
                <div className="ml-2 text-base text-gray-500">
                    {text}
                </div>
            </div>
        </div>
    )
}

const StaleForm = (
    {
        onChange,
        onSubmit,
        onCancel,
        value = {},
        disableSubmit,
        usagePrices,
        isMexicanStore,
        isBrazilianStore
    }) => {
    const orderPrice = usagePrices["product_value"]
    useEffect(() => {
        if (orderPrice > 0) onChange({ reimbursement_value: orderPrice })
    }, [])
    //DELIVERY_DELAY
    return (
        <>
            <div className="space-y-6 mt-6">
                {isMexicanStore && (
                    <div className="mt-1">
                        <div className="flex flex-col">
                            <span className="text-base font-bold text-gray-900">{i18n.t("claims.create.form_title_preloaded")}
                            </span>
                            <span className="text-base text-gray-500 mt-2 mb-3">{i18n.t("claims.create.currency_preloaded.subtitle")}
                            </span>
                            <div className="text-base text-gray-500 mr-3 mt-2 p-4 bg-gray-50 rounded-lg relative">
                                <div className="flex mb-2 font-semibold">
                                    <p>{i18n.t("claims.create.currency_preloaded.concept")}</p>
                                    <p className="ml-auto">{i18n.t("claims.create.currency_preloaded.amount")}</p>
                                </div>
                                <div className="border-b border-gray-400"></div>
                                {usagePrices["cost_cubbo"] != null &&
                                    <div className="flex mt-2">
                                        <p>{i18n.t("claims.create.currency_preloaded.processing_cost")}</p>
                                        <p className="ml-auto">${usagePrices["cost_cubbo"]} MXN</p>
                                    </div>
                                }
                                {usagePrices["cost_shipping"] != null &&
                                    <div className="flex mt-2">
                                        <p>{i18n.t("claims.create.currency_preloaded.shipping_labels_cost")}</p>
                                        <p className="ml-auto">${usagePrices["cost_shipping"]} MXN</p>
                                    </div>
                                }
                                {usagePrices["cost_return_shipping"] != null &&
                                    <div className="flex mt-2">
                                        <p>{i18n.t("claims.create.currency_preloaded.cost_return_shipping")}</p>
                                        <p className="ml-auto">${usagePrices["cost_return_shipping"]} MXN</p>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                )}
                {!isBrazilianStore &&
                    <>
                        <TextSection
                            title={i18n.t("claims.create.generic.form_title")}
                            text={i18n.t("claims.create.generic.form_subtitle")}
                        />
                        <div className="py-6 px-8 space-y-6 bg-gray-50 rounded-lg">
                            <FilesUploadSection
                                label={i18n.t("claims.create.generic.invoice_file")}
                                required
                                onChange={files => onChange({ invoice_file: files })}
                                files={value.invoice_file}
                            />
                        </div>
                    </>
                }
                <div>
                    <TextareaField
                        className="sm:col-span-6"
                        label={i18n.t("claims.create.notes_input.title")}
                        helpText={i18n.t("claims.create.notes_input.help")}
                        onChange={_value => onChange({ notes: _value })}
                        value={value.notes}
                        required
                    />
                </div>
            </div>
            <MainButtons disableSubmit={disableSubmit} onSubmit={() => onSubmit()} onCancel={onCancel} />
        </>
    )
}

const CarriersRequiringOfficialId = ['FEDEX', 'DHL']

const NotReceivedForm = (
    {
        onChange,
        onSubmit,
        onCancel,
        value = {},
        disableSubmit,
        carrierName,
        isBrazilianStore,
        usagePrices,
        isMexicanStore
    }
    //FALSE_DELIVERY
) => {
    const orderPrice = usagePrices["product_value"]
    useEffect(() => {
        if (orderPrice > 0) onChange({ reimbursement_value: orderPrice })
    }, [])
    return (
        <>
            <div className="space-y-6 mt-6">
                <div className="flex flex-col">
                    <span className="text-base font-bold text-gray-900">{i18n.t("claims.create.form_title_preloaded")}
                    </span>
                    <span className="text-base text-gray-500 mt-2 mb-3">{i18n.t("claims.create.currency_preloaded.subtitle")}
                    </span>
                    <div className="text-base text-gray-500 mr-3 mt-2 p-4 bg-gray-50 rounded-lg">
                        <div className="flex mb-2 font-semibold">
                            <p>{i18n.t("claims.create.currency_preloaded.concept")}</p>
                            <p className="ml-auto">{i18n.t("claims.create.currency_preloaded.amount")}</p>
                        </div>
                        <div className="border-b border-gray-400"></div>
                        {usagePrices["cost_cubbo"] != null &&
                            <div className="flex mt-2">
                                <p>{i18n.t("claims.create.currency_preloaded.processing_cost")}</p>
                                <p className="ml-auto">${usagePrices["cost_cubbo"]} MXN</p>
                            </div>
                        }
                        {usagePrices["cost_shipping"] != null &&
                            <div className="flex mt-2">
                                <p>{i18n.t("claims.create.currency_preloaded.shipping_labels_cost")}</p>
                                <p className="ml-auto">${usagePrices["cost_shipping"]} MXN</p>
                            </div>
                        }
                        <div className={`flex mt-2  ${orderPrice > 0 ? "" : "mb-3.5"}`}>
                            <p>
                                {i18n.t("claims.create.currency_preloaded.products_price")}
                                {!(orderPrice > 0) && <span className="text-red-500"> *</span>}
                            </p>
                            {orderPrice > 0 ?
                                <p className="ml-auto">{isMexicanStore ? `$${(orderPrice / 1.16)?.toFixed(2)} MXN` : `R$${orderPrice}`}</p>
                                :
                                <CurrencyInputField
                                    icon={<TagIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                                    required
                                    help={isMexicanStore && i18n.t("claims.create.currency_preloaded.without_iva")}
                                    className="sm:col-span-6 max-h-5 w-[25%] mt-3 pb-7 mb-5 ml-auto"
                                    onChange={_value => onChange({ reimbursement_value: _value })}
                                    value={value.reimbursement_value}
                                />
                            }
                        </div>
                    </div>
                </div>
                {!isBrazilianStore && 
                    <>
                        <TextSection
                            title={i18n.t("claims.create.generic.form_title")}
                            text={i18n.t("claims.create.generic.form_subtitle")}
                        />
                        <div className="py-6 px-8 space-y-6 bg-gray-50 rounded-lg">
                            { CarriersRequiringOfficialId.includes(carrierName) &&
                                <ImageUploadSection
                                    label={i18n.t("claims.create.incorrect_content.wrong_order.official_id_images")}
                                    required
                                    onChange={images => onChange({ official_id_images: images })}
                                    images={value.official_id_images}
                                />}
                            <FilesUploadSection
                                label={i18n.t("claims.create.generic.invoice_file")}
                                required
                                onChange={files => onChange({ invoice_file: files })}
                                files={value.invoice_file}
                            />
                        </div>
                    </>
                }
                <div>
                    <TextareaField
                        className="sm:col-span-6"
                        label={i18n.t("claims.create.notes_input.title")}
                        helpText={i18n.t("claims.create.notes_input.help")}
                        onChange={_value => onChange({ notes: _value })}
                        value={value.notes}
                        required
                    />
                </div>
            </div>
            <MainButtons disableSubmit={disableSubmit} onSubmit={() => onSubmit()} onCancel={onCancel} />
        </>
    )
}

const StolenForm = (
    {
        duplicateOrder
    }) => (
    //PACKAGE_STOLEN
    <div className="space-y-6 mt-6">
        <TextSection
            title={i18n.t("claims.create.package_stolen.sorry_text.title")}
            text={i18n.t("claims.create.package_stolen.sorry_text.help")}
        />
        <TextSection
            title={i18n.t("claims.create.package_stolen.advice.title")}
            text={i18n.t("claims.create.package_stolen.advice.help")}
        />
        <Button onClick={() => duplicateOrder()} type="primary">{i18n.t("claims.create.duplicate_order_button")}</Button>
    </div>
)

const LostForm = (
    {
        onChange,
        onSubmit,
        onCancel,
        value = {},
        disableSubmit,
        usagePrices,
        isMexicanStore,
        isBrazilianStore
    }
    //PACKAGE_LOST
) => {
    const orderPrice = usagePrices["product_value"]
    useEffect(() => {
        if (orderPrice > 0) onChange({ reimbursement_value: orderPrice })
    }, [])
    return (
        <>
            <div className="space-y-6 mt-6">
                <div className="flex flex-col">
                    <span className="text-base font-bold text-gray-900">{i18n.t("claims.create.form_title_preloaded")}
                    </span>
                    <span className="text-base text-gray-500 mt-2 mb-3">{i18n.t("claims.create.currency_preloaded.subtitle")}
                    </span>
                    <div className="text-base text-gray-500 mr-3 mt-2 p-4 bg-gray-50 rounded-lg">
                        <div className="flex mb-2 font-semibold">
                            <p>{i18n.t("claims.create.currency_preloaded.concept")}</p>
                            <p className="ml-auto">{i18n.t("claims.create.currency_preloaded.amount")}</p>
                        </div>
                        <div className="border-b border-gray-400"></div>
                        {usagePrices["cost_cubbo"] != null &&
                            <div className="flex mt-2">
                                <p>{i18n.t("claims.create.currency_preloaded.processing_cost")}</p>
                                <p className="ml-auto">${usagePrices["cost_cubbo"]} MXN</p>
                            </div>
                        }
                        {usagePrices["cost_shipping"] != null &&
                            <div className="flex mt-2">
                                <p>{i18n.t("claims.create.currency_preloaded.shipping_labels_cost")}</p>
                                <p className="ml-auto">${usagePrices["cost_shipping"]} MXN</p>
                            </div>
                        }
                        <div className={`flex mt-2  ${orderPrice > 0 ? "" : "mb-3.5"}`}>
                            <p>
                                {i18n.t("claims.create.currency_preloaded.products_price")}
                                {!(orderPrice > 0) && <span className="text-red-500"> *</span>}
                            </p>
                            {orderPrice > 0 ?
                                <p className="ml-auto">{isMexicanStore ? `$${(orderPrice / 1.16)?.toFixed(2)} MXN` : `R$${orderPrice}`}</p>
                                :
                                <CurrencyInputField
                                    icon={<TagIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                                    required
                                    help={isMexicanStore && i18n.t("claims.create.currency_preloaded.without_iva")}
                                    className="sm:col-span-6 max-h-5 w-[25%] mt-3 pb-7 mb-5 ml-auto"
                                    onChange={_value => onChange({ reimbursement_value: _value })}
                                    value={value.reimbursement_value}
                                />
                            }
                        </div>
                    </div>
                </div>
                {!isBrazilianStore &&
                    <>
                        <TextSection
                            title={i18n.t("claims.create.generic.form_title")}
                            text={i18n.t("claims.create.generic.form_subtitle")}
                        />
                        <div className="py-6 px-8 space-y-6 bg-gray-50 rounded-lg">
                                <FilesUploadSection
                                    label={i18n.t("claims.create.generic.invoice_file")}
                                    required
                                    onChange={files => onChange({ invoice_file: files })}
                                    files={value.invoice_file}
                                />
                        </div>
                    </>
                }
                <div className="mt-2">
                    <TextareaField
                        className="sm:col-span-6"
                        label={i18n.t("claims.create.notes_input.title")}
                        onChange={_value => onChange({ notes: _value })}
                        value={value.notes}
                        required
                    />
                </div>
            </div>
            <MainButtons disableSubmit={disableSubmit} onSubmit={() => onSubmit()} onCancel={onCancel} />
        </>
    )
}

const DamagedForm = (
    {
        onChange,
        onSubmit,
        onCancel,
        value = {},
        disableSubmit,
        isBrazilianStore,
        usagePrices,
        allProductsSelected,
        handleAllProductsSelected,
        order,
        handleSelectedProducts,
        selectedProducts,
        allProducts,
        onAllProductsRadioChange,
        handleInputQuantityUpdate,
        setSelectedProducts,
        setUsagePrices,
        allProductsValue,
        setAllProductsValue,
        isMexicanStore,
        hasNullPrice,
        discountPrice
    }
) => {
    const orderPrice = (order?.total_price - order?.buyer_shipping_price) || 0
    const productValues = usagePrices["product_value"]
    useEffect(() => {
        if (orderPrice > 0) onChange({ reimbursement_value: productValues })
    }, [])
    //DAMAGED_PRODUCT
    return (
        <>
            <div className="space-y-6 mt-6">
                <div className="flex flex-col">
                    <span className="text-base font-bold text-gray-900">{i18n.t("claims.create.form_title_preloaded")}
                    </span>
                    <span className="text-base text-gray-500 mt-2 mb-3">{i18n.t("claims.create.currency_preloaded.subtitle")}
                    </span>
                    <div className="text-base text-gray-500 mr-3 mt-2 p-4 pb-9 bg-gray-50 rounded-lg">
                        <div className="flex mb-2 font-semibold">
                            <p>{i18n.t("claims.create.currency_preloaded.concept")}</p>
                            <p className="ml-auto">{i18n.t("claims.create.currency_preloaded.amount")}</p>
                        </div>
                        <div className="border-b border-gray-400"></div>
                        {usagePrices["cost_cubbo"] != null &&
                            <div className="flex mt-2">
                                <p>{i18n.t("claims.create.currency_preloaded.processing_cost")}</p>
                                <p className="ml-auto">${usagePrices["cost_cubbo"]} MXN</p>
                            </div>
                        }
                        {usagePrices["cost_shipping"] != null &&
                            <div className="flex mt-2">
                                <p>{i18n.t("claims.create.currency_preloaded.shipping_labels_cost")}</p>
                                <p className="ml-auto">${usagePrices["cost_shipping"]} MXN</p>
                            </div>
                        }
                        <div className={`mt-2 ${orderPrice > 0 ? "" : "mb-3.5"}`}>
                            <p className="mb-3">
                                {i18n.t("claims.create.currency_preloaded.products_price")}
                                {!(orderPrice > 0) && <span className="text-red-500"> *</span>}
                            </p>
                            <ProductSelectorTable
                                allProductsSelected={allProductsSelected}
                                handleAllProductsSelected={handleAllProductsSelected}
                                order={order}
                                handleSelectedProducts={handleSelectedProducts}
                                selectedProducts={selectedProducts}
                                allProducts={allProducts}
                                onAllProductsRadioChange={onAllProductsRadioChange}
                                usagePrices={usagePrices}
                                handleInputQuantityUpdate={handleInputQuantityUpdate}
                                setSelectedProducts={setSelectedProducts}
                                setUsagePrices={setUsagePrices}
                                allProductsValue={allProductsValue}
                                setAllProductsValue={setAllProductsValue}
                                isMexicanStore={isMexicanStore}
                                hasNullPrice={hasNullPrice}
                                discountPrice={discountPrice}
                            />

                        </div>
                    </div>
                </div>
                <TextSection
                    title={i18n.t("claims.create.damaged_product.form_title")}
                    text={i18n.t("claims.create.damaged_product.form_subtitle")}
                />
                <div className="py-6 px-8 space-y-6 bg-gray-50 rounded-lg">
                    <ImageUploadSection
                        label={i18n.t("claims.create.damaged_product.damaged_images")}
                        required
                        onChange={images => onChange({ damaged_product_images: images })}
                        images={value.damaged_product_images}
                    />
                    <ImageUploadSection
                        label={i18n.t("claims.create.damaged_product.label_image")}
                        required
                        onChange={images => onChange({ label_images: images })}
                        images={value.label_images}
                    />
                    <ImageUploadSection
                        label={i18n.t("claims.create.damaged_product.package_images")}
                        required
                        onChange={images => onChange({ package_images: images })}
                        images={value.package_images}
                    />
                    {!isBrazilianStore &&
                        <FilesUploadSection
                            label={i18n.t("claims.create.generic.invoice_file")}
                            required
                            onChange={files => onChange({ invoice_file: files })}
                            files={value.invoice_file}
                        />
                    }
                </div>
                <TextareaField
                    className="sm:col-span-6"
                    label={i18n.t("claims.create.notes_input.title")}
                    onChange={_value => onChange({ notes: _value })}
                    value={value.notes}
                    required
                />
            </div>
            <MainButtons disableSubmit={disableSubmit} onSubmit={() => onSubmit()} onCancel={onCancel} />
        </>
    )
}

const mustShowMotive = function (motive, selectedMotive) {
    if (!selectedMotive) return true

    return selectedMotive === motive
}

const updateMotiveState = function (motive, selectedMotive, setSelectedMotive) {
    if (selectedMotive) return false

    setSelectedMotive(motive)
}

const UnwantedProductForm = (
    {
        onChange,
        onSubmit,
        onCancel,
        value = {},
        disableSubmit,
        usagePrices,
        isBrazilianStore
    }
) => {
    const orderPrice = usagePrices["product_value"]
    useEffect(() => {
        if (orderPrice > 0) onChange({ reimbursement_value: orderPrice })
    }, [])
    //UNWANTED_PRODUCTS
    return (
        <>
            <div className="space-y-6 mt-6">
                <div className="flex flex-col">
                    <span className="text-base font-bold text-gray-900">{i18n.t("claims.create.form_title_preloaded")}
                    </span>
                    <span className="text-base text-gray-500 mt-2 mb-3">{i18n.t("claims.create.currency_preloaded.subtitle")}
                    </span>
                    <div className="text-base text-gray-500 mr-3 mt-2 p-4 bg-gray-50 rounded-lg">
                        <div className="flex mb-2 font-semibold">
                            <p>{i18n.t("claims.create.currency_preloaded.concept")}</p>
                            <p className="ml-auto">{i18n.t("claims.create.currency_preloaded.amount")}</p>
                        </div>
                        <div className="border-b border-gray-400"></div>
                        {usagePrices["cost_return_shipping"] != null &&
                            <div className="flex mt-2">
                                <p>{i18n.t("claims.create.currency_preloaded.cost_return_shipping")}</p>
                                <p className="ml-auto">${usagePrices["cost_return_shipping"]} MXN</p>
                            </div>
                        }
                    </div>
                </div>
                <TextSection
                    title={i18n.t("claims.create.incorrect_content.unwanted_products.form_title")}
                    text={i18n.t("claims.create.incorrect_content.unwanted_products.form_subtitle")}
                />
                <div className="py-6 px-8 space-y-6 bg-gray-50 rounded-lg">
                    <ImageUploadSection
                        label={i18n.t("claims.create.incorrect_content.unwanted_products.unwanted_products_images")}
                        required
                        onChange={images => onChange({ unwanted_products_images: images })}
                        images={value.unwanted_products_images}
                    />
                    <ImageUploadSection
                        label={i18n.t("claims.create.incorrect_content.unwanted_products.label_image")}
                        required
                        onChange={images => onChange({ label_images: images })}
                        images={value.label_images}
                    />
                    <ImageUploadSection
                        label={i18n.t("claims.create.incorrect_content.unwanted_products.package_images")}
                        required
                        onChange={images => onChange({ package_images: images })}
                        images={value.package_images}
                    />
                    {!isBrazilianStore &&
                        <FilesUploadSection
                            label={i18n.t("claims.create.generic.invoice_file")}
                            required
                            onChange={files => onChange({ invoice_file: files })}
                            files={value.invoice_file}
                        />
                    }
                </div>
                <TextareaField
                    className="sm:col-span-6"
                    label={i18n.t("claims.create.notes_input.title")}
                    onChange={_value => onChange({ notes: _value })}
                    value={value.notes}
                    required
                />
            </div>
            <MainButtons disableSubmit={disableSubmit} onSubmit={() => onSubmit()} onCancel={onCancel} />
        </>
    )
}

const WrongOrderForm = (
    {
        onChange,
        onSubmit,
        onCancel,
        value = {},
        disableSubmit,
        usagePrices,
        isMexicanStore,
        isBrazilianStore
    }
) => {
    const orderPrice = usagePrices["product_value"]
    useEffect(() => {
        if (orderPrice > 0) onChange({ reimbursement_value: orderPrice })
    }, [])

    //WRONG_ORDER
    return (
        <>
            <div className="space-y-6 mt-6">
                <div className="flex flex-col">
                    <span className="text-base font-bold text-gray-900">{i18n.t("claims.create.form_title_preloaded")}
                    </span>
                    <span className="text-base text-gray-500 mt-2 mb-3">{i18n.t("claims.create.currency_preloaded.subtitle")}
                    </span>
                    <div className="text-base text-gray-500 mr-3 mt-2 p-4 bg-gray-50 rounded-lg">
                        <div className="flex mb-2 font-semibold">
                            <p>{i18n.t("claims.create.currency_preloaded.concept")}</p>
                            <p className="ml-auto">{i18n.t("claims.create.currency_preloaded.amount")}</p>
                        </div>
                        <div className="border-b border-gray-400"></div>

                        {usagePrices["cost_cubbo"] != null &&
                            <div className="flex mt-2">
                                <p>{i18n.t("claims.create.currency_preloaded.processing_cost")}</p>
                                <p className="ml-auto">${usagePrices["cost_cubbo"]} MXN</p>
                            </div>
                        }
                        {usagePrices["cost_shipping"] != null &&
                            <div className="flex mt-2">
                                <p>{i18n.t("claims.create.currency_preloaded.shipping_labels_cost")}</p>
                                <p className="ml-auto">${usagePrices["cost_shipping"]} MXN</p>
                            </div>
                        }
                        {usagePrices["cost_return_shipping"] != null &&
                            <div className="flex mt-2">
                                <p>{i18n.t("claims.create.currency_preloaded.cost_return_shipping")}</p>
                                <p className="ml-auto">${usagePrices["cost_return_shipping"]} MXN</p>
                            </div>
                        }
                        <div className={`flex mt-2  ${orderPrice > 0 ? "" : "mb-3.5"}`}>
                            <p>
                                {i18n.t("claims.create.currency_preloaded.products_price")}
                                {!(orderPrice > 0) && <span className="text-red-500"> *</span>}
                            </p>
                            {orderPrice > 0 ?
                                <p className="ml-auto">{isMexicanStore ? `$${(orderPrice / 1.16)?.toFixed(2)} MXN` : `R$${orderPrice}`}</p>
                                :
                                <CurrencyInputField
                                    icon={<TagIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                                    required
                                    className="sm:col-span-6 ml-auto max-h-5 w-[25%]"
                                    help={isMexicanStore && i18n.t("claims.create.currency_preloaded.without_iva")}
                                    onChange={_value => onChange({ reimbursement_value: _value })}
                                    value={value.reimbursement_value}
                                />
                            }
                        </div>
                    </div>
                </div>
                <TextSection
                    title={i18n.t("claims.create.incorrect_content.wrong_order.form_title")}
                    text={i18n.t("claims.create.incorrect_content.wrong_order.form_subtitle")}
                />
                <div className="py-6 px-8 space-y-6 bg-gray-50 rounded-lg">
                    <ImageUploadSection
                        label={i18n.t("claims.create.incorrect_content.wrong_order.unwanted_products_images")}
                        required
                        onChange={images => onChange({ unwanted_products_images: images })}
                        images={value.unwanted_products_images}
                    />
                    <ImageUploadSection
                        label={i18n.t("claims.create.incorrect_content.wrong_order.label_image")}
                        required
                        onChange={images => onChange({ label_images: images })}
                        images={value.label_images}
                    />
                    <ImageUploadSection
                        label={i18n.t("claims.create.incorrect_content.wrong_order.package_images")}
                        required
                        onChange={images => onChange({ package_images: images })}
                        images={value.package_images}
                    />
                    {!isBrazilianStore &&
                        <FilesUploadSection
                            label={i18n.t("claims.create.generic.invoice_file")}
                            required
                            onChange={files => onChange({ invoice_file: files })}
                            files={value.invoice_file}
                        />
                    }
                </div>
                <TextareaField
                    className="sm:col-span-6"
                    label={i18n.t("claims.create.notes_input.title")}
                    onChange={_value => onChange({ notes: _value })}
                    value={value.notes}
                    required
                />
            </div>
            <MainButtons disableSubmit={disableSubmit} onSubmit={() => onSubmit()} onCancel={onCancel} />
        </>
    )
}

const PaidProductForm = (
    {
        onChange,
        onSubmit,
        onCancel,
        value = {},
        disableSubmit,
        usagePrices,
        allProductsSelected,
        handleAllProductsSelected,
        order,
        handleSelectedProducts,
        selectedProducts,
        allProducts,
        onAllProductsRadioChange,
        handleInputQuantityUpdate,
        setSelectedProducts,
        setUsagePrices,
        allProductsValue,
        setAllProductsValue,
        isMexicanStore,
        hasNullPrice,
        discountPrice,
        isBrazilianStore
    }
) => {
    const orderPrice = (order?.total_price - order?.buyer_shipping_price) || 0
    const productValues = usagePrices["product_value"]
    useEffect(() => {
        if (orderPrice > 0) onChange({ reimbursement_value: productValues })
    }, [])
    //MISSING_PRODUCTS
    return (
        <>
            <div className="space-y-6 mt-6">
                <div className="flex flex-col">
                    <span className="text-base font-bold text-gray-900">{i18n.t("claims.create.form_title_preloaded")}
                    </span>
                    <span className="text-base text-gray-500 mt-2 mb-3">{i18n.t("claims.create.currency_preloaded.subtitle")}
                    </span>
                    <div className="text-base text-gray-500 mr-3 mt-2 p-4 pb-9 bg-gray-50 rounded-lg">
                        <div className="flex mb-2 font-semibold">
                            <p>{i18n.t("claims.create.currency_preloaded.concept")}</p>
                            <p className="ml-auto">{i18n.t("claims.create.currency_preloaded.amount")}</p>
                        </div>
                        <div className="border-b border-gray-400"></div>
                        {usagePrices["cost_cubbo"] != null &&
                            <div className="flex mt-2">
                                <p>{i18n.t("claims.create.currency_preloaded.processing_cost")}</p>
                                <p className="ml-auto">${usagePrices["cost_cubbo"]} MXN</p>
                            </div>
                        }
                        {usagePrices["cost_shipping"] != null &&
                            <div className="flex mt-2">
                                <p>{i18n.t("claims.create.currency_preloaded.shipping_labels_cost")}</p>
                                <p className="ml-auto">${usagePrices["cost_shipping"]} MXN</p>
                            </div>
                        }
                        <div className={`mt-2 ${orderPrice > 0 ? "" : "mb-3.5"}`}>
                            <p className="mb-3">
                                {i18n.t("claims.create.currency_preloaded.products_price")}
                                {!(orderPrice > 0) && <span className="text-red-500"> *</span>}
                            </p>
                            <ProductSelectorTable
                                allProductsSelected={allProductsSelected}
                                handleAllProductsSelected={handleAllProductsSelected}
                                order={order}
                                handleSelectedProducts={handleSelectedProducts}
                                selectedProducts={selectedProducts}
                                allProducts={allProducts}
                                onAllProductsRadioChange={onAllProductsRadioChange}
                                usagePrices={usagePrices}
                                handleInputQuantityUpdate={handleInputQuantityUpdate}
                                setSelectedProducts={setSelectedProducts}
                                setUsagePrices={setUsagePrices}
                                allProductsValue={allProductsValue}
                                setAllProductsValue={setAllProductsValue}
                                isMexicanStore={isMexicanStore}
                                hasNullPrice={hasNullPrice}
                                discountPrice={discountPrice}
                            />

                        </div>
                    </div>
                </div>
                <TextSection
                    title={i18n.t("claims.create.incorrect_content.missing_products.paid_product.form_title")}
                    text={i18n.t("claims.create.incorrect_content.missing_products.paid_product.form_subtitle")}
                />
                <div className="py-6 px-8 space-y-6 bg-gray-50 rounded-lg">
                    <ImageUploadSection
                        label={i18n.t("claims.create.incorrect_content.missing_products.paid_product.label_image")}
                        required
                        onChange={images => onChange({ label_images: images })}
                        images={value.label_images}
                    />
                    <ImageUploadSection
                        label={i18n.t("claims.create.incorrect_content.missing_products.paid_product.package_images")}
                        required
                        onChange={images => onChange({ package_images: images })}
                        images={value.package_images}
                    />
                    {!isBrazilianStore &&
                        <FilesUploadSection
                            label={i18n.t("claims.create.generic.invoice_file")}
                            required
                            onChange={files => onChange({ invoice_file: files })}
                            files={value.invoice_file}
                        />
                    }
                </div>
                <TextareaField
                    className="sm:col-span-6"
                    label={i18n.t("claims.create.notes_input.title")}
                    onChange={_value => onChange({ notes: _value })}
                    value={value.notes}
                    required
                />
            </div>
            <MainButtons disableSubmit={disableSubmit} onSubmit={() => onSubmit()} onCancel={onCancel} />
        </>
    )
}

const redirectToNewTicketPage = () => window.open(i18n.t("claims.create.none_of_the_above.url"), '_blank');

const PromotionalProductForm = function () {
    return (
        <div className="space-y-6 mt-6">
            <TextSection
                title={i18n.t("claims.create.incorrect_content.missing_products.promotional_product.sorry_text.title")}
                text={i18n.t("claims.create.incorrect_content.missing_products.promotional_product.sorry_text.help")}
            />
            <Button onClick={() => redirectToNewTicketPage()} type="primary">{i18n.t("claims.create.none_of_the_above.add_ticket_button")}</Button>
        </div>
    )
}

const NoneOfTheAboveForm = function () {
    return (
        <div className="space-y-6 mt-6">
            <TextSection
                title={i18n.t("claims.create.none_of_the_above.sorry_text.title")}
                text={i18n.t("claims.create.none_of_the_above.sorry_text.help")}
            />
            <Button onClick={() => redirectToNewTicketPage()} type="primary">{i18n.t("claims.create.none_of_the_above.add_ticket_button")}</Button>
        </div>
    )
}

const MissingProductOptions = function (
    {
        selectedMotive,
        setSelectedMotive,
        isDisabledRadioItem = null
    }
) {
    const MISSING_PRODUCTS_MOTIVES = [
        'PAID_PRODUCT',
        'PROMOTIONAL_PRODUCT'
    ]

    return (
        <div>
            <div className="mt-4">
                <span className="text-base font-bold">
                    {i18n.t('claims.create.incorrect_content.missing_products.problems_list.title')}
                </span>
            </div>
            <div className="mt-2 p-4 bg-gray-50 rounded-lg">
                <div className="col-span-4 space-y-3">
                    {MISSING_PRODUCTS_MOTIVES.map(motive =>
                        <RadioItem
                            motive={motive}
                            text={i18n.t('claims.create.incorrect_content.missing_products.problems_list.' + motive.toLowerCase())}
                            show={mustShowMotive(motive, selectedMotive)}
                            checked={!!selectedMotive}
                            onChange={checked => updateMotiveState(motive, selectedMotive, setSelectedMotive)}
                            key={motive + '-RadioItem'}
                            onEditSelection={() => setSelectedMotive(null)}
                            disabled={isDisabledRadioItem(motive)}
                            errorMessage={i18n.t("claims.errors.related_unresolved_existing_claim")}
                        />
                    )}
                </div>
            </div>

        </div>
    )
}

const WrongProductOptions = function (
    {
        selectedMotive,
        setSelectedMotive,
        clearState,
        isDisabledRadioItem
    }) {
    const WRONG_PRODUCT_MOTIVES = [
        'MISSING_PRODUCTS',
        'UNWANTED_PRODUCTS',
        'WRONG_ORDER'
    ]

    return (
        <div className="pt-2">
            <div className="mt-2">
                <span className="text-base font-bold">{i18n.t('claims.create.incorrect_content.problems_list.title')}</span>
            </div>
            <div className="mt-2 p-4 bg-gray-50 rounded-lg">
                <div className="col-span-4 space-y-3">
                    {WRONG_PRODUCT_MOTIVES.map(motive =>
                        <RadioItem
                            motive={motive}
                            text={i18n.t('claims.create.incorrect_content.problems_list.' + motive.toLowerCase())}
                            show={mustShowMotive(motive, selectedMotive)}
                            checked={!!selectedMotive}
                            onChange={checked => updateMotiveState(motive, selectedMotive, setSelectedMotive)}
                            key={motive + '-RadioItem'}
                            onEditSelection={() => clearState()}
                            disabled={isDisabledRadioItem(motive)}
                            errorMessage={i18n.t("claims.errors.related_unresolved_existing_claim")}
                        />
                    )}
                </div>
            </div>

        </div>
    )
}

export const CreateClaimContainer = function (
    {
        onSuccess,
        onCancel
    }) {
    const context = useContext(UserContext);
    const isBrazilianStore = context.user?.current_store?.warehouses[0]?.country === 'BR'
    const isMexicanStore = context.user?.current_store?.warehouses[0]?.country === 'MX'
    const { order_id } = useParams()
    const [usages, setUsages] = useState(null)
    const {
        isLoading,
        isError,
        error,
        data: order,
    } = useQuery(
        ['order-for-claim-creation', order_id],
        () => fetchOrderForClaimCreation(order_id),
        {
            onSuccess: async (claimData) => {
                const usagesData = await fetchUsages(order_id)
                setUsages(usagesData)
            }
        }
    )

    const [selectedMotive, setSelectedMotive] = useState(null)
    const [allProductsSelected, setAllProductsSelected] = useState(true)
    const [selectedProducts, setSelectedProducts] = useState([])
    const [allProducts, setAllProducts] = useState(true)
    const [categoryPendingClaims, setCategoryPendingClaims] = useState([])
    const [hasNullPrice, setHasNullPrice] = useState(false)
    const [discountPrice, setDiscountPrice] = useState(0)

    const onAllProductsRadioChange = (val) => {
        if (val === "ACCEPTED") setAllProducts(true)
        else setAllProducts(false)
    }

    const orderPrice = (order?.total_price - order?.buyer_shipping_price) || 0

    const [usagePrices, setUsagePrices] = useState({
        cost_cubbo: 0,
        cost_shipping: 0,
        product_value: 0,
        cost_return_shipping: 0,
        total: 0,
        totalAllProducts: 0
    })

    const REIMBURSABLES = {
        UNWANTED_PRODUCTS: ["cost_return_shipping"],
        MISSING_PRODUCTS: ["cost_cubbo", "cost_shipping", "product_value"],
        WRONG_ORDER: ["cost_cubbo", "cost_shipping", "product_value", "cost_return_shipping"],
        FALSE_DELIVERY: ["cost_cubbo", "cost_shipping", "product_value"],
        PACKAGE_LOST: ["cost_cubbo", "cost_shipping", "product_value"],
        DAMAGED_PRODUCT: ["cost_cubbo", "cost_shipping", "product_value"],
        DELIVERY_DELAY: ["cost_cubbo", "cost_shipping", "cost_return_shipping"],
        PACKAGE_STOLEN: []
    }

    const handleAllProductsSelected = (value) => {
        if (value) {
            setAllProductsSelected(true)
            const orderLinesWithReimbursableQuantity = order?.order_lines?.map(orderLine => ({
                ...orderLine,
                reimbursable_quantity: orderLine.quantity,
                price_per_item: orderLine?.price_per_item - orderLine?.discount_per_item,
            }))
            setSelectedProducts(orderLinesWithReimbursableQuantity)
        } else {
            setAllProductsSelected(false)
            setSelectedProducts([])
        }
    }

    const handleSelectedProducts = (orderLine, isChecked) => {
        let newProductsList = []
        if (isChecked) {
            const newOrderLine = {
                ...orderLine,
                reimbursable_quantity: orderLine.quantity,
                price_per_item: orderLine?.price_per_item - orderLine?.discount_per_item,
            }
            newProductsList = [...selectedProducts, newOrderLine]
        } else {
            newProductsList = selectedProducts.filter(product => product.id !== orderLine.id)
        }
        setSelectedProducts(newProductsList)
    }

    const handleInputQuantityUpdate = (orderLine, value) => {
        const updatedProductsList = selectedProducts?.map(product => {
            if (product.id === orderLine.id) {
                return {
                    ...product,
                    reimbursable_quantity: value
                }
            }
            return product
        })

        setSelectedProducts(updatedProductsList)
    }

    useEffect(() => {
        if (order) {
            const discount_price = order?.order_lines?.reduce((total, order_line) => total + (parseFloat(order_line?.discount_per_item) || 0) * (parseFloat(order_line?.quantity) || 1), 0) || 0
            setDiscountPrice(discount_price)
            const hasNullPrice = order?.order_lines?.some(order_line => order_line.price_per_item === null) || order?.order_lines?.every(order_line => order_line.price_per_item == 0)
            setHasNullPrice(hasNullPrice)
            if (hasNullPrice) {
                if (isMexicanStore) {
                    if((order?.total_price - order?.buyer_shipping_price) > 0){
                        const orderLinesWithReimbursableQuantity = order?.order_lines?.map(orderLine => ({
                            ...orderLine,
                            price_per_item: ((orderLine?.price_per_item - orderLine?.discount_per_item) / 1.16) || 0,
                            price: ((orderLine?.price_per_item - orderLine?.discount_per_item) / 1.16) || 0,
                            reimbursable_quantity: orderLine.quantity
                        }))
                        setSelectedProducts(orderLinesWithReimbursableQuantity)
                        setAllProductsValue((order?.total_price - discount_price) / 1.16)    
                    }else{
                        const orderLinesWithReimbursableQuantity = order?.order_lines?.map(orderLine => ({
                            ...orderLine,
                            price_per_item: ((orderLine?.price_per_item - orderLine?.discount_per_item)) || 0,
                            price: ((orderLine?.price_per_item - orderLine?.discount_per_item)) || 0,
                            reimbursable_quantity: orderLine.quantity
                        }))
                        setSelectedProducts(orderLinesWithReimbursableQuantity)
                        setAllProductsValue((order?.total_price - discount_price))
                    }
                }
                else {
                    const orderLinesWithReimbursableQuantity = order?.order_lines?.map(orderLine => ({
                        ...orderLine,
                        price_per_item: orderLine?.price_per_item - orderLine?.discount_per_item || 0,
                        price: orderLine?.price_per_item - orderLine?.discount_per_item || 0,
                        reimbursable_quantity: orderLine.quantity
                    }))
                    setSelectedProducts(orderLinesWithReimbursableQuantity)
                    if (orderPrice > 1) setAllProductsValue(order?.total_price - discount_price)
                }
            } else {
                const orderLinesWithReimbursableQuantity = order?.order_lines?.map(orderLine => ({
                    ...orderLine,
                    price_per_item: (orderLine?.price_per_item - orderLine?.discount_per_item) / 1.16 || 0,
                    price: (orderLine?.price_per_item - orderLine?.discount_per_item) / 1.16 || 0,
                    reimbursable_quantity: orderLine.quantity
                }))
                setSelectedProducts(orderLinesWithReimbursableQuantity)
            }


            const pendingClaims = order?.claims.map((claim) => {
                if (claim?.status === "PENDING") {
                    return claim.category
                } else {
                    return null
                }
            }).filter(result => result != null)

            setCategoryPendingClaims(pendingClaims)
        }
    }, [order])

    const CLAIM_MOTIVES = [
        'DELIVERY_DELAY',
        'FALSE_DELIVERY',
        'PACKAGE_LOST',
        'DAMAGED_PRODUCT',
        'INCORRECT_CONTENT',
        'NONE_OF_THE_ABOVE'
    ]

    if (!isBrazilianStore)
        CLAIM_MOTIVES.push('PACKAGE_STOLEN')

    const requiredFieldsByMotive = {
        'DELIVERY_DELAY': {
            text_fields: ['notes', 'requested_value', 'reimbursement_value'],
            file_fields: []
        },
        'FALSE_DELIVERY': {
            text_fields: ['notes', 'reimbursement_value', 'requested_value'],
            file_fields: !isBrazilianStore && CarriersRequiringOfficialId.includes(order?.shipping_method?.carrier_name) ? ['official_id_images'] : []
        },
        'PACKAGE_LOST': {
            text_fields: ['notes', 'reimbursement_value', 'requested_value'],
            file_fields: []
        },
        'UNWANTED_PRODUCTS': {
            text_fields: ['notes', 'requested_value', 'reimbursement_value'],
            file_fields: ['unwanted_products_images', 'label_images', 'package_images'],
        },
        'WRONG_ORDER': {
            text_fields: ['notes', 'reimbursement_value', 'requested_value'],
            file_fields: ['unwanted_products_images', 'label_images', 'package_images'],
        },
        'MISSING_PRODUCTS': {
            text_fields: ['notes', 'claimed_products_value', 'requested_value', 'reimbursement_value'],
            file_fields: ['label_images', 'package_images']
        },
        'DAMAGED_PRODUCT': {
            text_fields: ['notes', 'reimbursement_value', 'claimed_products_value', 'requested_value'],
            file_fields: ['damaged_product_images', 'label_images', 'package_images']
        }
    }

    const motivesRequiringInvoice = ['DELIVERY_DELAY', 'FALSE_DELIVERY', 'PACKAGE_LOST', 'DAMAGED_PRODUCT', 'UNWANTED_PRODUCTS']
    motivesRequiringInvoice.forEach(motive => {
        if (!isBrazilianStore)
            requiredFieldsByMotive[motive].file_fields.push('invoice_file')
    })

    const history = useHistory()
    const { url: storePath } = useRouteMatch({ path: STORE_PATH })


    const [selectedWrongProductMotive, setSelectedWrongProductMotive] = useState(null)
    const [selectedMissingProductMotive, setSelectedMissingProductMotive] = useState(null)
    const [inputByMotive, setInputByMotive] = useState({})
    const [allProductsValue, setAllProductsValue] = useState(null)

    const [showConfirmDialog, setShowConfirmDialog] = useState(false)
    const [isSubmitLoading, setIsSubmitLoading] = useState(false)

    const handleInputUpdate = function (motive, input) {
        const newInputByMotive = {
            ...inputByMotive,
            [motive]: { ...inputByMotive[motive], ...input }
        }

        setInputByMotive(newInputByMotive)
    }

    const retrievSelectedMotive = function () {
        if (selectedMotive !== 'INCORRECT_CONTENT') {
            return selectedMotive
        }

        return selectedWrongProductMotive
    }

    useMemo(() => {
        if (usages) {
            const motive = retrievSelectedMotive()
            let orderUsages = usages?.store_billing_service_usages?.filter(usage => usage.billable_type === "Order") || null
            let shippingLabelUsages = usages?.store_billing_service_usages?.filter(usage => usage.billable_type === "ShippingLabel" && usage?.store_billing_service?.service_type != "RETURN_SHIPPING_LABEL") || null
            let returnLabelUsages = usages?.store_billing_service_usages?.filter(usage => usage?.billable_type === "ShippingLabel" && usage?.store_billing_service?.service_type === "RETURN_SHIPPING_LABEL") || null
            let productValue = parseFloat(orderPrice)

            const shippingLabelTotalPrice = shippingLabelUsages === null ? null : shippingLabelUsages?.reduce((total, usage) => total + parseFloat(usage.billed_total_price), 0) || 0
            let returnLabelTotalPrice = returnLabelUsages === null ? null : returnLabelUsages?.reduce((total, usage) => total + parseFloat(usage.billed_total_price), 0)

            let orderTotalPrice = orderUsages === null ? null : orderUsages?.reduce((total, usage) => total + parseFloat(usage.billed_total_price), 0)

            if (motive === "MISSING_PRODUCTS" || motive === "DAMAGED_PRODUCT") {
                orderTotalPrice = 0
                if (orderPrice > 0) {
                    if (allProducts) {
                        if (hasNullPrice) productValue = allProductsValue
                        else productValue = allProductsValue || productValue
                    } else {
                        if (!hasNullPrice) {
                            productValue = selectedProducts?.reduce((total, product) => total + (parseFloat(product?.price) || parseFloat(product?.price_per_item)) * parseFloat(product.reimbursable_quantity), 0) || 0
                        } else {
                            productValue = selectedProducts?.reduce((total, product) => {
                                const price = parseFloat(product?.price)
                                const quantity = parseFloat(product?.reimbursable_quantity)
                                if (isNaN(price) || isNaN(quantity)) {
                                    return total
                                }

                                return total + (price * quantity)
                            }, 0) || 0
                        }
                    }
                } else {
                    if (!allProducts) {
                        productValue = selectedProducts?.reduce((total, product) => total + parseFloat(product.price ? product.price : 0) * parseFloat(product.reimbursable_quantity), 0) || 0
                    } else {
                        productValue = allProductsValue
                    }
                }

                const pickAndPackUsage = usages?.store_billing_service_usages?.filter(usage => usage?.store_billing_service?.service_type === "PICK_AND_PACK") || null

                const pickAndPackPricePerItem = pickAndPackUsage === null ? null : parseFloat(pickAndPackUsage[0]?.billed_total_price) / parseFloat(pickAndPackUsage[0]?.billed_usage)

                const totalProducts = selectedProducts?.reduce((total, product) => total + parseFloat(product.reimbursable_quantity), 0) || 0

                if (pickAndPackUsage != null) orderTotalPrice += (totalProducts * pickAndPackPricePerItem)
                //else orderTotalPrice += (totalProducts)
            }

            const fixedProductValue = (Number(productValue) || 0).toFixed(2)

            setUsagePrices(prevState => {
                const newState = {
                    ...prevState,
                    cost_cubbo: orderUsages === null ? null : orderTotalPrice.toFixed(2),
                    cost_shipping: shippingLabelUsages === null ? null : shippingLabelTotalPrice.toFixed(2),
                    cost_return_shipping: returnLabelUsages === null ? null : returnLabelTotalPrice.toFixed(2),
                    product_value: productValue != null ? fixedProductValue : 0,
                }

                

                const total = newState.total = REIMBURSABLES[motive]?.reduce((total, concept) => {
                    if (!isNaN(parseFloat(newState[concept]))) {
                        return total + parseFloat(newState[concept])
                    }
                    return total
                }, 0)

                newState.total = total?.toFixed(2)

                const totalWithoutProductPrices = newState.total = REIMBURSABLES[motive]?.reduce((total, concept) => {
                    if (!isNaN(parseFloat(newState[concept])) && concept != "product_value") {
                        return total + parseFloat(newState[concept])
                    }
                    return total
                }, 0)

                if (motive === "MISSING_PRODUCTS" || motive === "DAMAGED_PRODUCT") {
                    handleInputUpdate(motive, { requested_value: totalWithoutProductPrices?.toFixed(2), reimbursement_value: productValue })
                } else {
                    handleInputUpdate(motive, { requested_value: totalWithoutProductPrices?.toFixed(2) })
                }

                return newState
            })
        }
    }, [usages, selectedMotive, selectedWrongProductMotive, selectedProducts, allProductsValue, allProducts])

    useEffect(() => {
        const motive = retrievSelectedMotive()
        const processedSelectedProducts = selectedProducts?.map(selectedProduct => {
            return {
                id: selectedProduct?.id,
                reimbursable_quantity: selectedProduct?.reimbursable_quantity,
                price: selectedProduct?.price !== undefined ? selectedProduct?.price : null
            }
        })
        const stringifiedProcessedSelectedProducts = JSON.stringify(processedSelectedProducts)
        handleInputUpdate(motive, { claimed_products_value: stringifiedProcessedSelectedProducts })
    }, [selectedProducts])

    const buildParameters = function (motive) {
        const input = inputByMotive[motive]

        let formData = new FormData()
        formData.append("order_id", order_id)
        formData.append("motive", motive)

        requiredFieldsByMotive[motive]['file_fields']?.forEach(field => {
            input[field].forEach((file) => formData.append(field + "[]", file))
        })

        requiredFieldsByMotive[motive]['text_fields'].forEach(field => {
            if (field === 'claimed_products_value') {
                if (!allProducts) {
                    formData.append(field, input[field])
                } else {
                    formData.append(field, "")
                }
            } else if (field === "reimbursement_value") {
                if (isNaN(input['reimbursement_value'])) {
                    formData.append(field, 0)
                } else {
                    if (isMexicanStore) {
                        const maxReimbursement = 30 * UMA_PRICE_APRIL_2024
                        if (orderPrice > 0) {
                            if (hasNullPrice) {
                                formData.append(field, input[field])
                            } else {
                                const orderPriceWithoutIVA = input[field] / 1.16
                                const finalPrice = Math.min(orderPriceWithoutIVA, maxReimbursement)
                                const fixedFinalPrice = (Number(finalPrice) || 0)?.toFixed(2)
                                formData.append(field, fixedFinalPrice)
                            }
                        } else {
                            const price = input[field]
                            const finalPrice = Math.min(price, maxReimbursement)
                            formData.append(field, finalPrice)
                        }
                    } else {
                        formData.append(field, input[field])
                    }
                }
            } else if (field === "requested_value" && motive != "UNWANTED_PRODUCTS" && motive != "DELIVERY_DELAY") {
                let finalPrice = 0
                if (isMexicanStore) {
                    const maxReimbursement = 30 * UMA_PRICE_APRIL_2024
                    if (orderPrice > 0) {
                        if (hasNullPrice) {
                            finalPrice = parseFloat(input["reimbursement_value"]) + parseFloat(input[field])
                            const fixedFinalPrice = (Number(finalPrice) || 0)?.toFixed(2)
                            formData.append(field, fixedFinalPrice)
                        } else {
                            const orderPriceWithoutIVA = input["reimbursement_value"] / 1.16 
                            finalPrice = Math.min(orderPriceWithoutIVA, maxReimbursement)
                            finalPrice = parseFloat(finalPrice) + parseFloat(input[field])
                            const fixedFinalPrice = (Number(finalPrice) || 0)?.toFixed(2)
                            formData.append(field, fixedFinalPrice)
                        }
                    } else {
                        const price = input["reimbursement_value"]
                        finalPrice = Math.min(price, maxReimbursement)
                        finalPrice = parseFloat(finalPrice) + parseFloat(input[field])
                        const fixedFinalPrice = (Number(finalPrice) || 0)?.toFixed(2)
                        formData.append(field, fixedFinalPrice)
                    }
                } else {
                    finalPrice = parseFloat(input["reimbursement_value"]) + parseFloat(input[field])
                    const fixedFinalPrice = (Number(finalPrice) || 0)?.toFixed(2)
                    formData.append(field, fixedFinalPrice)
                }
            } else {
                formData.append(field, input[field])
            }
        })

        return formData
    }

    const handleSubmit = async function () {
        setIsSubmitLoading(true)

        const motive = retrievSelectedMotive()
        const params = buildParameters(motive)

        try {
            await createClaim(params)
            setShowConfirmDialog(false)
            onSuccess(order_id, motive)
        } catch (error) {
            window.alert(error)
        }

        setIsSubmitLoading(false)
    }

    const buildShippingMethod = order => (order.shipping_method?.carrier_name || '-') + (order.is_cod ? '- COD' : '')
    const buildOrderNumber = function (order) {
        const cartId = order.order_number !== order.cart_id?.toString && order.cart_id

        return cartId ? `${order.order_number} Cart ${cartId}` : order.order_number
    }
    const buildStatusPill = function (order) {
        if (!order) return null

        const orderStatus = order.is_pick_and_ship && order.pick_n_ship_status && order.status === 'pending' ? order.pick_n_ship_status : order.status

        if (order.shipping_status) {
            return (
                <div className="col-span-2">
                    <StatusPill
                        className="mt-2"
                        status={order.shipping_status}
                        statuses={TRACKING_STATUSES_DEFINITIONS}
                    />
                    <div>
                        {order.shipping_status === 'shipment_delivered' &&
                            Intl.DateTimeFormat(i18n.language, {
                                year: "numeric",
                                month: "long",
                                day: "2-digit",
                                hour: 'numeric',
                                minute: 'numeric'
                            }).format(new Date(order.delivery_date))}
                    </div>
                </div>
            )
        }

        return (
            <StatusPill
                className="mt-2"
                status={orderStatus}
                statuses={ORDER_STATUSES}
            />
        )
    }

    const clearAllMotives = function () {
        setSelectedMotive(null)
        setSelectedWrongProductMotive(null)
        setSelectedMissingProductMotive(null)
    }

    const clearWrongProductMotiveOnwards = function () {
        setSelectedWrongProductMotive(null)
        setSelectedMissingProductMotive(null)
    }

    const shippedMoreThanAMonthAgo = (shipping_date) => (Math.ceil(new Date() - new Date(shipping_date)) / (1000 * 60 * 60 * 24)) > 30

    if (isLoading || usages === null) return <Loader show />
    if (isError) return <div>Error: {error.message}</div>

    if (!CLAIM_ENABLED_STATUSES.includes(order.status)) return (
        <div>
            <span className="italic">
                {i18n.t("orders.claimContainer.disputes")}
            </span>
        </div>
    )

    if (shippedMoreThanAMonthAgo(order.shipping_date)) return (
        <div>
            <span className="italic">
                {i18n.t("orders.claimContainer.initiate_disputes")}
            </span>
        </div>
    )

    const isDisabledRadioItem = (motive) => {
        return categoryPendingClaims.includes(motive)
    }

    const disableSubmit = function (motive) {
        if (!requiredFieldsByMotive.hasOwnProperty(motive)) return false
        if (["DELIVERY_DELAY", "UNWANTED_PRODUCTS"].includes(motive)) {
            const hasNotes = inputByMotive[motive]?.['notes'];
            const hasInvoiceFile = inputByMotive[motive]?.['invoice_file'];
            
            if (isMexicanStore) {
                return !(hasNotes && hasInvoiceFile);
            } else {
                return !hasNotes;
            }
        }
        const allTextFieldsPresent = requiredFieldsByMotive[motive]['text_fields'].every(field => inputByMotive[motive]?.[field])
        const allFileFieldsPresent = requiredFieldsByMotive[motive]['file_fields'].every(field => inputByMotive[motive]?.[field]?.length)

        return !(allTextFieldsPresent && allFileFieldsPresent)
    }

    return (
        <>
            <div className="space-y-8 divide-y divide-gray-200">
                <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                    <OrderDetail value={buildOrderNumber(order)} label={i18n.t('claims.create.order_number')} id="order_number" />
                    <OrderDetail child={buildStatusPill(order)} label={i18n.t('claims.create.order_status')} id="status" />
                    <OrderDetail value={buildShippingMethod(order)} label={i18n.t('claims.create.shipping_method')} id="carrier_name" />
                    <OrderDetail value={order.shipping_labels?.at(-1)?.shipping_number || '-'} label={i18n.t('claims.create.tracking_number')} id="shipping_number" />
                </div>
                <div className="pt-2">
                    <div className="mt-2">
                        <span className="text-base font-bold">{i18n.t('claims.create.problems_list.title')}</span>
                    </div>
                    <div className="mt-2 p-4 bg-gray-50 rounded-lg">
                        <div className="col-span-4 space-y-3">
                            {CLAIM_MOTIVES.map(motive =>
                                <RadioItem
                                    motive={motive}
                                    text={i18n.t('claims.create.problems_list.' + motive.toLowerCase())}
                                    show={mustShowMotive(motive, selectedMotive)}
                                    checked={!!selectedMotive}
                                    onChange={checked => updateMotiveState(motive, selectedMotive, setSelectedMotive)}
                                    key={motive + '-RadioItem'}
                                    onEditSelection={() => clearAllMotives()}
                                    disabled={isDisabledRadioItem(motive)}
                                    errorMessage={i18n.t("claims.errors.related_unresolved_existing_claim")}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {selectedMotive === 'DELIVERY_DELAY' &&
                <StaleForm
                    value={inputByMotive['DELIVERY_DELAY']}
                    onChange={input => handleInputUpdate('DELIVERY_DELAY', input)}
                    onSubmit={() => setShowConfirmDialog(true)}
                    onCancel={() => onCancel()}
                    disableSubmit={disableSubmit('DELIVERY_DELAY')}
                    usagePrices={usagePrices}
                    isMexicanStore={isMexicanStore}
                    isBrazilianStore={isBrazilianStore}
                />
            }
            {selectedMotive === 'FALSE_DELIVERY' &&
                <NotReceivedForm
                    value={inputByMotive['FALSE_DELIVERY']}
                    onChange={input => handleInputUpdate('FALSE_DELIVERY', input)}
                    onSubmit={() => setShowConfirmDialog(true)}
                    onCancel={() => onCancel()}
                    disableSubmit={disableSubmit('FALSE_DELIVERY')}
                    carrierName={order.shipping_method?.carrier_name}
                    isBrazilianStore={isBrazilianStore}
                    usagePrices={usagePrices}
                    isMexicanStore={isMexicanStore}
                />
            }
            {selectedMotive === 'PACKAGE_STOLEN' && <StolenForm duplicateOrder={() => history.push(storePath + DUPLICATE_ORDER.replace(':id', order_id))} />}
            {selectedMotive === 'PACKAGE_LOST' &&
                <LostForm
                    value={inputByMotive['PACKAGE_LOST']}
                    onChange={input => handleInputUpdate('PACKAGE_LOST', input)}
                    onSubmit={() => setShowConfirmDialog(true)}
                    onCancel={() => onCancel()}
                    disableSubmit={disableSubmit('PACKAGE_LOST')}
                    order={order}
                    usagePrices={usagePrices}
                    isMexicanStore={isMexicanStore}
                    isBrazilianStore={isBrazilianStore}
                />
            }
            {selectedMotive === 'DAMAGED_PRODUCT' &&
                <DamagedForm
                    value={inputByMotive['DAMAGED_PRODUCT']}
                    onChange={input => handleInputUpdate('DAMAGED_PRODUCT', input)}
                    onSubmit={() => setShowConfirmDialog(true)}
                    onCancel={() => onCancel()}
                    disableSubmit={disableSubmit('DAMAGED_PRODUCT')}
                    isBrazilianStore={isBrazilianStore}
                    usagePrices={usagePrices}
                    handleInputUpdate={handleInputUpdate}
                    allProductsSelected={allProductsSelected}
                    handleAllProductsSelected={handleAllProductsSelected}
                    order={order}
                    handleSelectedProducts={handleSelectedProducts}
                    selectedProducts={selectedProducts}
                    allProducts={allProducts}
                    onAllProductsRadioChange={onAllProductsRadioChange}
                    handleInputQuantityUpdate={handleInputQuantityUpdate}
                    setSelectedProducts={setSelectedProducts}
                    setUsagePrices={setUsagePrices}
                    allProductsValue={allProductsValue}
                    setAllProductsValue={setAllProductsValue}
                    setAllProductsSelected={setAllProductsSelected}
                    isMexicanStore={isMexicanStore}
                    hasNullPrice={hasNullPrice}
                    discountPrice={discountPrice}
                />
            }
            {selectedMotive === 'NONE_OF_THE_ABOVE' && <NoneOfTheAboveForm />}
            {selectedMotive === 'INCORRECT_CONTENT' &&
                <WrongProductOptions
                    selectedMotive={selectedWrongProductMotive}
                    setSelectedMotive={setSelectedWrongProductMotive}
                    clearState={clearWrongProductMotiveOnwards}
                    isDisabledRadioItem={isDisabledRadioItem}
                />
            }
            {selectedWrongProductMotive === 'MISSING_PRODUCTS' &&
                <MissingProductOptions
                    selectedMotive={selectedMissingProductMotive}
                    setSelectedMotive={setSelectedMissingProductMotive}
                    isDisabledRadioItem={isDisabledRadioItem}
                />
            }
            {selectedWrongProductMotive === 'UNWANTED_PRODUCTS' &&
                <UnwantedProductForm
                    value={inputByMotive['UNWANTED_PRODUCTS']}
                    onChange={input => handleInputUpdate('UNWANTED_PRODUCTS', input)}
                    onSubmit={() => setShowConfirmDialog(true)}
                    onCancel={() => onCancel()}
                    disableSubmit={disableSubmit('UNWANTED_PRODUCTS')}
                    usagePrices={usagePrices}
                    isBrazilianStore={isBrazilianStore}
                />
            }
            {selectedWrongProductMotive === 'WRONG_ORDER' &&
                <WrongOrderForm
                    value={inputByMotive['WRONG_ORDER']}
                    onChange={input => handleInputUpdate('WRONG_ORDER', input)}
                    onSubmit={() => setShowConfirmDialog(true)}
                    onCancel={() => onCancel()}
                    disableSubmit={disableSubmit('WRONG_ORDER')}
                    usagePrices={usagePrices}
                    isMexicanStore={isMexicanStore}
                    isBrazilianStore={isBrazilianStore}
                />
            }
            {selectedMissingProductMotive === 'PAID_PRODUCT' &&
                <PaidProductForm
                    value={inputByMotive['MISSING_PRODUCTS']}
                    onChange={input => handleInputUpdate('MISSING_PRODUCTS', input)}
                    handleInputUpdate={handleInputUpdate}
                    onSubmit={() => setShowConfirmDialog(true)}
                    onCancel={() => onCancel()}
                    disableSubmit={disableSubmit('MISSING_PRODUCTS')}
                    usagePrices={usagePrices}
                    allProductsSelected={allProductsSelected}
                    handleAllProductsSelected={handleAllProductsSelected}
                    order={order}
                    handleSelectedProducts={handleSelectedProducts}
                    selectedProducts={selectedProducts}
                    allProducts={allProducts}
                    onAllProductsRadioChange={onAllProductsRadioChange}
                    handleInputQuantityUpdate={handleInputQuantityUpdate}
                    setSelectedProducts={setSelectedProducts}
                    setUsagePrices={setUsagePrices}
                    allProductsValue={allProductsValue}
                    setAllProductsValue={setAllProductsValue}
                    setAllProductsSelected={setAllProductsSelected}
                    isMexicanStore={isMexicanStore}
                    hasNullPrice={hasNullPrice}
                    discountPrice={discountPrice}
                    isBrazilianStore={isBrazilianStore}
                />
            }
            {selectedMissingProductMotive === 'PROMOTIONAL_PRODUCT' && <PromotionalProductForm />}
            <ConfirmDialog
                open={showConfirmDialog}
                setOpen={setShowConfirmDialog}
                title={i18n.t("orders.claimContainer.initiate_dispute")}
                description={`${i18n.t("orders.claimContainer.create_disput")} ${i18n.t("orders.claimContainer.the_order")} ${order?.order_number}`}
                confirmLabel={i18n.t("orders.claimContainer.confirm")}
                onConfirm={() => handleSubmit()}
                cancelLabel={i18n.t("orders.claimContainer.cancel")}
                loading={isSubmitLoading}
            />
        </>
    )
}